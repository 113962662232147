/* eslint-disable no-unused-vars */
import {
  SEARCH_PRODUCT,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  GET_PRODUCT_UNIT,
  GET_PRODUCT_TYPE,
  FILTER_PRODUCT,
  GET_PRODUCT,
} from '../constants';

const INIT_STATE = {
  data: { data: [], count: 0 },
  filters: {
    page: 0,
    limit: 10,
    sort: 'desc',
    order_by: 'created_datetime'
  },
  initial: {
    "id": null,
    "product_id": "",
    "product_type_id": null,
    "product_name_th": "",
    "product_name_en": "",
    "product_detail_th": "",
    "product_detail_en": "",
    "product_image": [],
    "packing_size": null,
    "weight": 0,
    "product_unit_id": "",
    "price": 0,
    "recommended": 0,
    "status_active": null,
    "start_period": null,
    "end_period": null,
  },
  type: { data: [], count: 0 },
  unit: { data: [], count: 0 }

};

const ProductReducer = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case SEARCH_PRODUCT:
      return {
        ...state,
        initial: action.data?.id ? action.data : {
          "id": null,
          "product_id": "",
          "product_type_id": null,
          "product_name_th": "",
          "product_name_en": "",
          "product_detail_th": "",
          "product_detail_en": "",
          "product_image": [],
          "packing_size": null,
          "weight": 0,
          "product_unit_id": "",
          "price": 0,
          "recommended": 0,
          "start_period": null,
          "end_period": null,
        },
      };

    case GET_PRODUCT:
      return {
        ...state,
        data: action.data.data,
      };
    case GET_PRODUCT_UNIT:
      return {
        ...state,
        unit: action.data.data,
      };
    case GET_PRODUCT_TYPE:
      return {
        ...state,
        type: action.data.data,
      };
    case FILTER_PRODUCT:
      return {
        ...state,
        filters: action.data,
      };
    case ADD_PRODUCT:
      return action.data;
    case UPDATE_PRODUCT:
      return action.data;

    // case SEARCH_NOTES:
    //   return {
    //     ...state,
    //     noteSearch: action.searchTerm,
    //   };

    // case UPDATE_NOTE:
    //   return {
    //     ...state,
    //     notes: state.notes.map((note) =>
    //       note.id === action.id ? { ...note, [action.field]: action.value } : note,
    //     ),
    //   };
    // case DELETE_NOTE:
    //   return {
    //     ...state,
    //     notes: state.notes.map((note) =>
    //       note.id === action.id ? { ...note, deleted: !note.deleted } : note,
    //     ),
    //   };
    // case ADD_NOTE:
    //   return {
    //     ...state,
    //     notes: [
    //       ...state.notes,
    //       {
    //         // eslint-disable-next-line no-param-reassign
    //         id: action.id++,
    //         title: action.title,
    //         color: action.color,
    //         datef: new Date(),
    //         deleted: false,
    //       },
    //     ],
    //   };

    default:
      return state;
  }
};

export default ProductReducer;
