/* eslint-disable no-unused-vars */
import moment from 'moment';
import {
  GET_DAILY_REPORT,
  FILTER_DAILY_REPORT,
  GET_PARTNERS,
  GET_PROVINCES,
  GET_WORK_TYPE,
  GET_CONTACT_PERSON,
  GET_PARTNER_TYPE,
  GET_PARTNER_PROJECTS,
  GET_DAILY_REPORT_USER,
  GET_DAILY_REPORT_BY_ID,
  GET_LEAVE_TYPE,
  GET_LOADING,
  GET_PARTNER_GROUP_DETAILS,
  SET_DAILY_REPORT
} from '../constants';


const INIT_STATE = {
  loading: false,
  daily_report: { data: [], count: 0 },
  daily_report_user: { data: [], count: 0 },
  leave_type: { data: [], count: 0 },
  provinces: { data: [], count: 0 },
  partners: { data: [], count: 0 },
  work_type: { data: [], count: 0 },
  contact_person: { data: [], count: 0 },
  partner_type: { data: [], count: 0 },
  partenr_group_details: { data: [], count: 0 },
  partner_projects: { data: [], count: 0 },
  filters: {
    page: 1,
    limit: 10,
    sort: 'desc',
    order_by: 'created_datetime'
  },
  initial: {
    report_date: null,
    insertlist: [{
      "report_start": new Date(`${moment().format('YYYY-MM-DD')} 00:00:00`),
      "report_end": new Date(`${moment().format('YYYY-MM-DD')} 23:59:59`),
      "report_type": "กิจกรรม",
      "work_type": "",
      "work_type_other": "",
      "project_id": "",
      "project_name": null,
      "product_type": "",
      "partner_id": null,
      "partner_type": "",
      // "partner_type_id": "",
      "contact_person_id": null,
      "travel": "",
      "contact_person": {
        "firstname": "",
        "lastname": "",
        "nickname": "",
        "position": "",
        "department": "",
        "phone": "",
        "email": ""
      },
      "report_detail": "",
      "files": [
      ],
      "todo_list": {
        "todo_date": null,
        "todo_detail": ""
      }
    }]
  },

};

const DailyreportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DAILY_REPORT_BY_ID:
      console.log(action?.data)
      return {
        ...state,
        loading: true,
        initial: (action?.data?.report_date || false) && action?.data?.insertlist?.length > 0 ? action?.data : {
          report_date: action?.data?.date,
          insertlist: [{
            "report_start": new Date(`${moment(action?.data?.date).format('YYYY-MM-DD')} 00:00:00`),
            "report_end": new Date(`${moment(action?.data?.date).format('YYYY-MM-DD')} 23:59:59`),
            "report_type": "กิจกรรม",
            "work_type": "",
            "work_type_other": "",
            "project_id": "",
            "project_name": null,
            "product_type": [],
            "partner_id": null,
            "partner_type": "",
            // "partner_type_id": "",
            "contact_person_id": null,
            "travel": "",
            "contact_person": {
              "firstname": "",
              "lastname": "",
              "nickname": "",
              "position": "",
              "department": "",
              "phone": "",
              "email": "",
              'number': ""
            },
            "report_detail": "",
            "files": [
            ],
            "todo_list": {
              "todo_date": null,
              "todo_detail": ""
            }
          }]
        },
      };
    case GET_LEAVE_TYPE:
      return {
        ...state,
        loading: true,
        leave_type: action.data.data,
      };
    case GET_DAILY_REPORT_USER:
      return {
        ...state,
        loading: true,
        daily_report_user: action.data.data,
      };
    case GET_DAILY_REPORT:
      return {
        ...state,
        loading: true,
        daily_report: action.data.data,
      };
    case GET_PROVINCES:
      return {
        ...state,
        loading: true,
        provinces: action.data.data,
      };
    case GET_WORK_TYPE:
      return {
        ...state,
        loading: true,
        work_type: action.data.data,
      };
    case GET_PARTNERS:
      return {
        ...state,
        loading: true,
        partners: action.data.data,
      };
    case GET_CONTACT_PERSON:
      return {
        ...state,
        loading: true,
        contact_person: action.data.data,
      };
    case GET_PARTNER_TYPE:
      return {
        ...state,
        loading: true,
        partner_type: action.data.data,
      };
    case GET_PARTNER_GROUP_DETAILS:
      return {
        ...state,
        loading: true,
        partenr_group_details: action.data.data,
      };
    case GET_PARTNER_PROJECTS:
      return {
        ...state,
        loading: true,
        partner_projects: action.data.data,
      };
    case FILTER_DAILY_REPORT:
      return {
        ...state,
        loading: true,
        filters: action.data,
      };
    case SET_DAILY_REPORT:
      return {
        ...state,
        loading: true,
        provinces: { data: [], count: 0 },
        partners: { data: [], count: 0 },
        partner_projects: { data: [], count: 0 },
        contact_person: { data: [], count: 0 },
      };
    case GET_LOADING:
      return {
        ...state,
        loading: action.data
      };



    default:
      return state;
  }
};

export default DailyreportReducer;
