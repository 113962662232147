/* eslint-disable no-unused-vars */
import React from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import useScrollReset from './hooks/useScrollReset';
import 'react-perfect-scrollbar/dist/css/styles.css';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const auth = useAuth();
  const location = useLocation();

  useScrollReset();


  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {auth.isInitialized ? routing : <SplashScreen />}
        {/* {routing} */}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
