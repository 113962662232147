/* eslint-disable no-unused-vars */
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from '../components/AuthGuard';
import GuestGuard from '../components/GuestGuard';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import DashboardLayout from '../layouts/full-layout/FullLayout';
import Indexofrole from '../components/Indexofrole';
import PermissionGuard from '../components/PermissionGuard';



/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
// const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

/* ****Pages***** */
const Dashboard1 = Loadable(lazy(() => import('../views/dashboards/Dashboard1')));

/* ****Appscustom***** */
const NotificationList = Loadable(lazy(() => import('../views/apps/notification/NotificationList')));
const ChangeList = Loadable(lazy(() => import('../views/apps/notifychange/ChangeList')));

const ActivityCreate = Loadable(lazy(() => import('../views/apps/dailyreport/ActivityCreate')));
const ActivityUpdate = Loadable(lazy(() => import('../views/apps/dailyreport/ActivityUpdate')));

const Calendar = Loadable(lazy(() => import('../views/apps/calendar/ACalendar')));
const ComingSoon = Loadable(lazy(() => import('../views/coming_soon')));

const CustomersCreate = Loadable(lazy(() => import('../views/apps/customers/CustomersCreate')));
const CustomersList = Loadable(lazy(() => import('../views/apps/customers/CustomersList')));
const CustomersEdit = Loadable(lazy(() => import('../views/apps/customers/CustomersEdit')));
const CustomersView = Loadable(lazy(() => import('../views/apps/customers/CustomersView')));
//const ManagementList = Loadable(lazy(() => import('../views/apps/customers/ManagementList')));

/* ****Routes***** */

const Router = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    // element: <FullLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <Indexofrole />
          </AuthGuard>
        )
        // element: <Indexofrole />
      },
      {
        path: '/dashboards/dashboard1',
        exact: true,
        element:
          <PermissionGuard permission={['Management', 'Sales']}>
            <Dashboard1 />
          </PermissionGuard>
      },
      {
        path: 'dailyreport',
        children: [
          {
            path: 'calendar',
            element:
              <PermissionGuard permission={['Management', 'Sales',]}>
                <Calendar />
              </PermissionGuard>

          },
          // {
          //   path: 'create',
          //   element:
          //     <PermissionGuard permission={['Management', 'Sales',]}>
          //       <ActivityCreate />
          //     </PermissionGuard>
          // },
          // {
          //   path: 'update/:id',
          //   element:
          //     <PermissionGuard permission={['Management', 'Sales',]}>
          //       <ActivityUpdate />
          //     </PermissionGuard>
          // },
        ]
      },
      // {
      //   path: 'product',
      //   children: [
      //     {
      //       path: 'lists',
      //       element:
      //         <PermissionGuard permission={['Management', 'Sales',]}>
      //           <ProductList />
      //         </PermissionGuard>

      //     },
      //     {
      //       path: 'create',
      //       element:
      //         <PermissionGuard permission={['Management', 'Sales',]}>
      //           <ProductCreate />
      //         </PermissionGuard>
      //     },
      //     {
      //       path: 'update/:id',
      //       element:
      //         <PermissionGuard permission={['Management', 'Sales',]}>
      //           <ProductUpdate />
      //         </PermissionGuard>
      //     },
      //   ]
      // },
      // {
      //   path: 'quotation',
      //   children: [
      //     {
      //       path: 'lists',
      //       element:
      //         <PermissionGuard permission={['Management', 'Sales',]}>
      //           <QuotationList />
      //         </PermissionGuard>
      //     },
      //     {
      //       path: 'create/:id',
      //       element:
      //         <PermissionGuard permission={['Management', 'Sales',]}>
      //           <QuotationCreate />
      //         </PermissionGuard>
      //     },
      //     {
      //       path: 'update/:id',
      //       element:
      //         <PermissionGuard permission={['Management', 'Sales',]}>
      //           <QuotationCreate />
      //         </PermissionGuard>
      //     },
      //     // {
      //     //   path: 'files/:id',
      //     //   element:
      //     //     <PermissionGuard permission={['Management', 'Sales', 'Client',]}>
      //     //       <QuotationPDF />
      //     //     </PermissionGuard>
      //     // },
      //   ]
      // },
 
        {
          path: 'customers',
          children: [
            {
              path: 'lists',
              element:
                <PermissionGuard permission={['Management', 'Sales']}>
                  <CustomersList />
                </PermissionGuard>
            },
            {
              path: 'create/:id',
              element:
                <PermissionGuard permission={['Management', 'Sales']}>
                  <CustomersCreate />
                </PermissionGuard>
            },
            {
              path: 'edit/:id',
              element:
                <PermissionGuard permission={['Management', 'Sales']}>
                  <CustomersEdit />
                </PermissionGuard>
            },
            {
              path: 'view/:id',
              element:
                <PermissionGuard permission={['Management', 'Sales']}>
                  <CustomersView />
                </PermissionGuard>
            }
          ]
        },
        // {
        //   path: 'management',
        //   children: [
        //     {
        //       path: 'lists',
        //       element:
        //         <PermissionGuard permission={['Management', 'Sales']}>
        //           <ManagementList />
        //         </PermissionGuard>
        //     }
            // },
            // {
            //   path: 'create/:id',
            //   element:
            //     <PermissionGuard permission={['Management', 'Sales']}>
            //       <CustomersCreate />
            //     </PermissionGuard>
            // },
            // {
            //   path: 'edit/:id',
            //   element:
            //     <PermissionGuard permission={['Management', 'Sales']}>
            //       <CustomersEdit />
            //     </PermissionGuard>
            // }
        //   ]
        // },
      // {
      //   path: 'shop',
      //   children: [
      //     {
      //       path: 'lists',
      //       element:
      //         <PermissionGuard permission={['Client',]}>
      //           <Shop />
      //         </PermissionGuard>
      //     },
      //     {
      //       path: 'detail/:id',
      //       element:
      //         <PermissionGuard permission={['Client',]}>
      //           <ShopDetail />
      //         </PermissionGuard>
      //     },
      //   ]
      // },
      // {
      //   path: '/orderhistory', exact: true, element:
      //     <PermissionGuard permission={['Client',]}>
      //       <OrderHistory />
      //     </PermissionGuard>
      // },
      // {
      //   path: '/orderhistory/:id', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales', 'Client']}>
      //       <OrderHistoryView />
      //     </PermissionGuard>
      // },

      // {
      //   path: '/orderlist', exact: true, element:
      //     <PermissionGuard permission={['Client',]}>
      //       <OrderList />
      //     </PermissionGuard>
      // },
      // {
      //   path: '/saleorderlist', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales',]}>
      //       <SaleOrderList />
      //     </PermissionGuard>
      // },
      // {
      //   path: '/saleorderlist/detail/:id', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales',]}>
      //       <SaleOrderDetail />
      //     </PermissionGuard>
      // },
      {
        path: '/notification', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales',]}>
            <NotificationList />
          </PermissionGuard>
      },
      // {
      //   path: '/change_request', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales',]}>
      //       <ChangeList />
      //     </PermissionGuard>
      // },
      // {
      //   path: '/delivery', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales',]}>
      //       <DeliveryList />
      //     </PermissionGuard>
      // },
      // {
      //   path: '/quotation', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales', 'Client']}>
      //       <ComingSoon />
      //     </PermissionGuard>
      // },
      // {
      //   path: '/appraise', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales', 'Client']}>
      //       <ComingSoon />
      //     </PermissionGuard>
      // },

      {
        path: '/preemption_certificate', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales', 'Client']}>
            <ComingSoon />
          </PermissionGuard>
      },
      {
        path: '/delivery_note', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales', 'Client']}>
            <ComingSoon />
          </PermissionGuard>
      },
      {
        path: '/check_product_status', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales', 'Client']}>
            <ComingSoon />
          </PermissionGuard>
      },
      {
        path: '/return_notice', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales', 'Client']}>
            <ComingSoon />
          </PermissionGuard>
      },
      {
        path: '/notify_the_change', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales', 'Client']}>
            <ComingSoon />
          </PermissionGuard>
      },
      // {
      //   path: '/partner_account', exact: true, element:
      //     <PermissionGuard permission={['Management', 'Sales', 'Client']}>
      //       <ComingSoon />
      //     </PermissionGuard>
      // },
      {
        path: '/customer_account', exact: true, element:
          <PermissionGuard permission={['Management', 'Sales', 'Client']}>
            <ComingSoon />
          </PermissionGuard>
      },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      {
        path: 'login', element:
          (<GuestGuard>
            <Login />
          </GuestGuard>)
        // <Login />
      },
      { path: 'register/:id', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  // {
  //   path: 'quotation',
  //   element: <BlankLayout />,
  //   children: [
  //     { path: 'files/:id', element: <QuotationPDF /> },
  //   ],
  // }
];

export default Router;
