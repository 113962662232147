/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
import axios from '../../utils/axios';
import {
  GET_QUOTATION_NOTIFI,
  GET_ORDER_NOTIFI,
} from '../constants';

export const getorder_notifi = (params) => async (dispatch) => {
  const response = await axios
    .get('/api/v0/order', { params: params })
    .then((responses) => {
      dispatch({
        type: GET_ORDER_NOTIFI,
        data: responses,
      });
    })
    .catch((err) => err);
};
export const getquotation_notifi = (params) => async (dispatch) => {
  const response = await axios
    .get('/api/v0/quotation', { params: params })
    .then((responses) => {
      dispatch({
        type: GET_QUOTATION_NOTIFI,
        data: responses,
      });
    })
    .catch((err) => err);
};

