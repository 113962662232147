/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// import { authApi } from '../__fakeApi__/authApi';
import axios from '../utils/axios';


const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    // axios.defaults.headers.common = { 'Authorization': `bearer ${accessToken}` }
    axios.defaults.headers.common = { 'authtoken': `${accessToken}` }
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common["Authorization"];
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    if (user) {
      user.role = 'Admin';
      user.avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif';
    }
    // console.log(isAuthenticated)

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    if (user) {
      user.role = 'Admin';
      user.avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif';
    }

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {

        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          axios.defaults.headers.common = { 'authtoken': `${accessToken}` }
          const user = await axios.post(process.env.REACT_APP_best_URL+'/current-user');
          console.log(user.data)
          if (user.data.status) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: user?.data || null
              }
            });
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }

        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    const formData = new FormData();
    formData.append("username", username)
    formData.append("password", password)

    // const response = await axios.post('/login', formData);
    // const { access_token, ret, token_type } = response.data;
    // // const user = {
    // //   id: '5e86809283e28b96d2d38537',
    // //   avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
    // //   email: 'demo@cannabis.dev',
    // //   name: 'Jane Rotanson',
    // //   password: 'Password123!',
    // //   role: 'Admin'
    // // }
    // if (response) {
    //   if (ret == 0) {
    //     setSession(access_token);
    //     localStorage.setItem('accessToken', access_token);

    //     const user = await axios.post('/session');
    //     dispatch({
    //       type: 'LOGIN',
    //       payload: {
    //         user: user.data?.user || null
    //       }
    //     });
    //   }
    // }
    try {
      const res = await axios({
        method: 'post',
        url: process.env.REACT_APP_best_URL+'/login',
        data: formData
      });
      try {
        const { token, ret, payload } = res.data;
        // setSession(token);
        if (token) {
          setSession(token);
          localStorage.setItem('accessToken', token);

          const user = await axios.post(process.env.REACT_APP_best_URL+'/current-user');
          dispatch({
            type: 'LOGIN',
            payload: {
              user: user?.data || null
            }
          });
        } else {
          return res;
        }
      }
      catch (err) {
        return "err";
      }

    } catch (error) {
      console.log(error); // this is the main part. Use the response property from the error object

      return error;
    }


    // const user = await authApi.me(accessToken);
    // const user = { fff: "ff" }


    // localStorage.setItem('accessToken', accessToken);

    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user
    //   }
    // });
  };

  const logout = async () => {
    // console.log("ddd")
    localStorage.removeItem('accessToken');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    // const accessToken = await authApi.register({ email, name, password });
    // const user = await authApi.me(accessToken);

    // localStorage.setItem('accessToken', accessToken);

    // dispatch({
    //   type: 'REGISTER',
    //   payload: {
    //     user
    //   }
    // });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
