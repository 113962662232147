/* eslint-disable prefer-const */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Grid,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  FormHelperText,
} from '@mui/material';
import { Link } from 'react-router-dom';

import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import CustomCheckbox from '../../forms/custom-elements/CustomCheckbox';
import CustomTextField from '../../forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../forms/custom-elements/CustomFormLabel';
import PageContainer from '../../container/PageContainer';

import img1 from '../../../assets/images/backgrounds/login-bg.svg';
import LogoIcon from '../../../layouts/full-layout/logo/LogoIcon';

import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import {
  getPseudoCode,
} from '../../../function/FunctionApi';
const LoginJWT = (props) => {
  const mounted = useMounted();
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    //const formData = new FormData();
    //formData.append('code', '1');
    const userLevel = '0';
    const userId ="";
    const createdBy ="Register";
    const userEmail ="";
    const userDepartmen ="";
    const userDivision ="";
    const userNameEn ="";
    const userNameTh ="";
    const userSection ="";

    const formData= {
      userLevel,
      userId, 
      createdBy,
      userEmail,
      userDepartmen,
      userDivision,
      userNameEn,
      userNameTh,
      userSection
    }
    getPseudoCode(formData)
      .then((res) => {
        //console.log("PseudoCode", res.data);
        const code = res.data;
        //console.log(`/customers/create/${code}`);
        navigate(`/auth/register/${code}`);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let dd = await login(values.username, values.password);
          console.log(dd);
          if (dd === undefined) {
            setStatus({ success: false });
            // setErrors({ submit: dd.data.msg });
            setSubmitting(false);
          } else {
            setStatus({ success: false });
            setErrors({ submit: 'Email or password incorrect' });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: 'Email or password incorrect' });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Box
            sx={{
              mt: 4,
            }}
          >
            {/* <CustomFormLabel htmlFor="email" >Email Address</CustomFormLabel> */}

            <CustomTextField
              label="อีเมล"
              id="email"
              variant="outlined"
              name="username"
              onChange={handleChange}
              value={values.username}
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
            />
            <CustomFormLabel htmlFor="password"></CustomFormLabel>

            <CustomTextField
              label="รหัสผ่าน"
              id="password"
              type="password"
              variant="outlined"
              name="password"
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onChange={handleChange}
              value={values.password}
              sx={{
                mb: 3,
              }}
            />

            {errors.submit && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <FormGroup>
              <Button
                color="success"
                variant="contained"
                size="large"
                fullWidth
                disabled={isSubmitting}
                type="submit"
                sx={{
                  pt: '10px',
                  pb: '10px',
                  mb: '10px',
                }}
              >
                เข้าสู่ระบบ
              </Button>
            </FormGroup>

            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'flex',
                  lg: 'flex',
                },
                alignItems: 'center',
              }}
            >
              <FormGroup>
                {/* <FormControlLabel
                  control={<CustomCheckbox defaultChecked />}
                  label="Remeber this Device"
                  sx={{
                    mb: 2,
                  }}
                /> */}
                <Typography
                  onClick={handleBack}
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    color: '#777e89',
                    fontSize: '0.875rem',
                    mb: '16px',
                    cursor:'pointer',
                  }}
                >
                  สร้างบัญชีใหม่
                </Typography>
              </FormGroup>

              <Box
                sx={{
                  ml: 'auto',
                }}
              >
                <Typography
                  component={Link}
                  to="/auth/reset-password"

                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    mb: '16px',
                    color: '#777e89',
                    fontSize: '0.875rem',
                  }}
                >
                  ลืมรหัสผ่าน
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                position: 'relative',
                textAlign: 'center',
                mt: '20px',
                mb: '20px',
                '&::before': {
                  content: '""',
                  background: (theme) => `${theme.palette.mode === 'dark' ? '#42464d' : '#ecf0f2'}`,
                  height: '1px',
                  width: '100%',
                  position: 'absolute',
                  left: '0',
                  top: '13px',
                },
              }}
            >
              {/* <Typography
                    component="span"
                    color="textSecondary"
                    variant="h6"
                    fontWeight="400"
                    sx={{
                      position: 'relative',
                      padding: '0 12px',
                      background: (theme) =>
                        `${theme.palette.mode === 'dark' ? '#282c34' : '#fff'}`,
                    }}
                  >
                    or sign in with
                  </Typography> */}
            </Box>

            {/* <Box>
                  <Button
                    variant="outlined"
                    size="large"
                    display="flex"
                    alignitems="center"
                    justifycontent="center"
                    sx={{
                      width: '100%',
                      borderColor: (theme) =>
                        `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                      borderWidth: '2px',
                      textAlign: 'center',
                      mt: 2,
                      pt: '10px',
                      pb: '10px',
                      '&:hover': {
                        borderColor: (theme) =>
                          `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                        borderWidth: '2px',
                      },
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <GoogleIcon
                        sx={{
                          color: (theme) => theme.palette.error.main,
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          ml: 1,
                          color: (theme) =>
                            `${
                              theme.palette.mode === 'dark' ? theme.palette.grey.A200 : '#13152a'
                            }`,
                        }}
                      >
                        Google
                      </Typography>
                    </Box>
                  </Button>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Button
                      variant="outlined"
                      size="large"
                      display="flex"
                      alignitems="center"
                      justifycontent="center"
                      sx={{
                        width: '100%',
                        borderColor: (theme) =>
                          `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                        borderWidth: '2px',
                        textAlign: 'center',
                        mt: 2,
                        pt: '10px',
                        pb: '10px',
                        '&:hover': {
                          borderColor: (theme) =>
                            `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                          borderWidth: '2px',
                        },
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <FacebookIcon
                          sx={{
                            color: (theme) => theme.palette.secondary.main,
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 1,
                            color: (theme) =>
                              `${
                                theme.palette.mode === 'dark' ? theme.palette.grey.A200 : '#13152a'
                              }`,
                          }}
                        >
                          Facebook
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Button
                      variant="outlined"
                      size="large"
                      display="flex"
                      alignitems="center"
                      justifycontent="center"
                      sx={{
                        width: '100%',
                        borderColor: (theme) =>
                          `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                        borderWidth: '2px',
                        textAlign: 'center',
                        mt: 2,
                        pt: '10px',
                        pb: '10px',
                        '&:hover': {
                          borderColor: (theme) =>
                            `${theme.palette.mode === 'dark' ? '#42464d' : '#dde3e8'}`,
                          borderWidth: '2px',
                        },
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <TwitterIcon
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            ml: 1,
                            color: (theme) =>
                              `${
                                theme.palette.mode === 'dark' ? theme.palette.grey.A200 : '#13152a'
                              }`,
                          }}
                        >
                          Twitter
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                </Grid> */}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
