/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import {
  GET_PROVINCE,
  GET_AMPHURES,
  GET_Tombons,
  GET_Zipcode,
  GET_contact_person,
  GET_restaurant,
  GET_customer,
  GET_customers,
  GET_group_partner,
  GET_employee,
  GET_shipping_address,
  QUOTATION_CLEAR
} from '../constants';

const INIT_STATE = {
  data: { data: [], count: 0 },
  Amphures: [],
  Province: [],
  Tombons: [],
  Zipcode: [],
  contact_person: [],
  shipping_address: [],
  restaurant: [],
  customer: [],
  group_partner: [],
  employee: {},

};

const AddressReducer = (state = INIT_STATE, action) => {
  console.log(action)
  switch (action.type) {
    case GET_PROVINCE:
      return {
        ...state,
        Province: action.data,
      };
    case GET_AMPHURES:
      return {
        ...state,
        Amphures: action.data,
      };
    case GET_Tombons:
      return {
        ...state,
        Tombons: action.data,
      };
    case GET_Zipcode:
      return {
        ...state,
        Zipcode: action.data,
      };
    case GET_contact_person:
      return {
        ...state,
        contact_person: action.data.data,
      };
    case QUOTATION_CLEAR:
      console.log(QUOTATION_CLEAR)
      return {
        ...state,
        contact_person: [],
        shipping_address: [],
        customer: [],
      };
    case GET_shipping_address:
      return {
        ...state,
        shipping_address: action.data.data,
      };
    case GET_restaurant:
      return {
        ...state,
        restaurant: action.data,
      };
    case GET_customer:
      return {
        ...state,
        customer: action.data.data[0],
      };
    case GET_customers:
      return {
        ...state,
        customer: action.data.data,
      };
    case GET_group_partner:
      return {
        ...state,
        group_partner: action.data,
      };
    case GET_employee:
      return {
        ...state,
        employee: action.data.data,
      };

    default:
      return state;
  }
};

export default AddressReducer;
