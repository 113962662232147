/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
// import * as data from './data';


const NotificationDropdown = ({ data, type, handleMorenoti }) => (
  <Box>
    {data.slice(0, 3).map((notification) => {
      if (type.type === "neworder") {
        return (
          <Box key={notification.title} onClick={(evt) => handleMorenoti(notification, "neworder")}>
            <MenuItem
              sx={{
                pt: 1,
                pb: 1,
                borderRadius: '0px',
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h5"
                >
                  <FeatherIcon icon="shopping-cart" />
                </Typography>
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      width: '400px',
                    }}
                  >
                    {`${notification.order_id} ส่งคำสั่งซื้อใหม่ ยอดคำสั่งซื้อ ${notification.total_price} บาท`}
                  </Typography>
                  <Typography
                    variant="h6"
                    noWrap
                    fontWeight="400"
                    sx={{
                      width: '240px',
                      fontSize: "smaller",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {moment(notification.created_datetime).fromNow()}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Box>
        );
        /* eslint no-else-return: "off" */
      } else if (type.type === "newquotation") {
        return (
          <Box key={notification.title} onClick={(evt) => handleMorenoti(notification, "newquotation")}>
            <MenuItem
              sx={{
                pt: 1,
                pb: 1,
                borderRadius: '0px',
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h5"
                >
                  <FeatherIcon icon="user" />
                </Typography>
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      width: '400px',
                    }}
                  >
                    {`${notification.quotation_id} ขออนุมัติใบเสนอราคาใหม่ ยอดสั่งซื้อ ${notification.total} บาท`}
                  </Typography>
                  <Typography
                    variant="h6"
                    noWrap
                    fontWeight="400"
                    sx={{
                      width: '240px',
                      fontSize: "smaller",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {moment(notification.created_datetime).fromNow()}

                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Box>
        );
        /* eslint no-else-return: "off" */
      } else {
        return (
          <Box key={notification.title} onClick={(evt) => handleMorenoti(notification, "newcustomer")}>
            <MenuItem
              sx={{
                pt: 1,
                pb: 1,
                borderRadius: '0px',
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h5"
                >
                  <FeatherIcon icon="file-text" />
                </Typography>
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      width: '400px',
                    }}
                  >
                    {`${notification.quotation_id} ขออนุมัติใบเสนอราคาใหม่ ยอดสั่งซื้อ ${notification.total} บาท`}
                  </Typography>
                  <Typography
                    variant="h6"
                    noWrap
                    fontWeight="400"
                    sx={{
                      width: '240px',
                      fontSize: "smaller",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    {moment(notification.created_datetime).fromNow()}

                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Box>
        );
      }
    })}
  </Box>
);

NotificationDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.array.isRequired
};

export default NotificationDropdown;
