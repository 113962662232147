/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import axios from 'axios';
//const baseUrl = 'http://localhost:3003/';
const baseUrl = `${process.env.REACT_APP_API_URL_ALL}`;

// Provinces
export const getProvince = async () => await axios.get(`${baseUrl}provinces`);

export const getAmpur = async (id) => await axios.get(`${baseUrl}amphures/${id}`);

export const getTombos = async (id) => await axios.get(`${baseUrl}tombons/${id}`);

export const getZipcode = async (id) => await axios.get(`${baseUrl}zipcode/${id}`);

// Group Partners

export const getPersonType = async () => await axios.get(`${baseUrl}person_type`);

export const getJuristic = async (id) => await axios.get(`${baseUrl}juristic/${id}`);

export const getGroupPartner = async (id) => await axios.get(`${baseUrl}customer_group/${id}`);

export const getGroupList = async (id) => await axios.get(`${baseUrl}list_customer_group/${id}`);

export const getSaveGroup = async (formData) =>
  await axios.post(`${baseUrl}group_details`, formData);

export const getShowGroupPartner = async (id) =>
  await axios.get(`${baseUrl}show_customer_group/${id}`);

//Country
export const getCountry = async () => await axios.get(`${baseUrl}country`);

export const getLogin = async (value) => await axios.post(`${baseUrl}login`, value);

export const currentUser = async (authtoken) =>
  await axios.post(
    `${baseUrl}current-user`,
    {},
    {
      headers: {
        authtoken,
      },
    },
  );

export const getGroup = async () => await axios.get(`${baseUrl}partner_group`);

export const getTest = async (id) => await axios.get(`${baseUrl}partner_group1/${id}`);

//
export const getInsertgroup = async (values) => await axios.post(`${baseUrl}insert_group`, values);

export const getCountgroup = async () => await axios.get(`${baseUrl}count_group`);

export const getPagepartners = async (formData) =>
  await axios.post(`${baseUrl}create_partners`, formData);

export const getUpdateCustome = async (formData) =>
  await axios.post(`${baseUrl}update_customer`, formData);

export const getRegister = async (formData) =>
  await axios.post(`${baseUrl}register_client`, formData);

export const getUpdatepartners = async (formData) =>
  await axios.post(`${baseUrl}update_partners`, formData);

export const getPseudoCode = async (formData) => await axios.post(`${baseUrl}pseudo`, formData);

export const getAllCustomer = async () => await axios.get(`${baseUrl}all_customer`);

export const getGroupCustomer = async (id) => await axios.get(`${baseUrl}customer_group/${id}`);

export const getGroupTwo = async (id) => await axios.get(`${baseUrl}list_groups/${id}`);

export const removeCustomer = async (id) => await axios.get(`${baseUrl}remov_customer/${id}`);

export const confirmNoneactiv = async (id) => await axios.get(`${baseUrl}confirm_customer/${id}`);

export const getCustomer = async (id) => await axios.get(`${baseUrl}customer/${id}`);

export const getDetailCustomer = async (id) => await axios.get(`${baseUrl}detail_customer/${id}`);

export const getBillingContact = async (id) => await axios.get(`${baseUrl}billing/${id}`);

export const getContactPerson = async (id) => await axios.get(`${baseUrl}contact_persons/${id}`);

export const getDocumentDelivery = async (id) =>
  await axios.get(`${baseUrl}document_delivery_list/${id}`);

export const getRestaurant = async (id) => await axios.get(`${baseUrl}restaurant/${id}`);

export const getShippingAddress = async (id) => await axios.get(`${baseUrl}shipping_address/${id}`);

export const getAccountCustomer = async (id) => await axios.get(`${baseUrl}account_customer/${id}`);

export const getContact = async (id) => await axios.get(`${baseUrl}contact/${id}`);

export const getImgcompany = async (id) => await axios.get(`${baseUrl}update_imgcompany/${id}`);

export const getImgCertificate = async (id) =>
  await axios.get(`${baseUrl}update_company_certificate/${id}`);

export const getImgVat = async (id) => await axios.get(`${baseUrl}update_vat_registration/${id}`);

export const getImgSeal = async (id) => await axios.get(`${baseUrl}update_company_seal/${id}`);

export const getOptionsPayment = async () => await axios.get(`${baseUrl}options_payment`);

export const getImgBilling = async (id) => await axios.get(`${baseUrl}update_file_billing/${id}`);

export const getImgShipping = async (id) =>
  await axios.get(`${baseUrl}update_file_googlemap/${id}`);

export const getImgMap = async (id) => await axios.get(`${baseUrl}update_company_map/${id}`);

export const getImgDocument = async (id) => await axios.get(`${baseUrl}update_file_document/${id}`);

export const getUpdatePassword = async (formData) =>
  await axios.post(`${baseUrl}update_password`, formData);

export const getDuplicate = async (formData) => await axios.post(`${baseUrl}duplicate`, formData);

export const getJuristicID = async (formData) =>
  await axios.post(`${baseUrl}juristic_id`, formData);

export const getUpdateCompanyPhoto = async (formData) =>
  await axios.post(`${baseUrl}update_company_photo`, formData);

export const getUpdateImgaddress = async (formData) =>
  await axios.post(`${baseUrl}update_imgaddress`, formData);

export const getUpdateCertificate = async (formData) =>
  await axios.post(`${baseUrl}update_certificate`, formData);

export const getUpdateVat = async (formData) => await axios.post(`${baseUrl}update_vat`, formData);

export const getUpdateSeal = async (formData) =>
  await axios.post(`${baseUrl}update_seal`, formData);

export const getUpdateBilling = async (formData) =>
  await axios.post(`${baseUrl}update_billing`, formData);

export const getUpdateDocumentDeilvery = async (formData) =>
  await axios.post(`${baseUrl}update_documentdeilvery`, formData);

export const getUpdateShippingAddress = async (formData) =>
  await axios.post(`${baseUrl}update_shippingaddress`, formData);

export const getUpdateActiv = async (formData) =>
  await axios.post(`${baseUrl}update_activ`, formData);

export const getOptionsBilling = async () => await axios.get(`${baseUrl}options_billing`);

export const removeCustomerGroup = async (id) =>
  await axios.delete(`${baseUrl}remov_group_customer/${id}`);

export const removePartnersAll = async (id) => await axios.get(`${baseUrl}remov_partner_all/${id}`);

export const getShowProject = async (id) => await axios.get(`${baseUrl}project_list/${id}`);

export const getSaveProject = async (formData) =>
  await axios.post(`${baseUrl}create_project`, formData);

export const removeProjectList = async (id) => await axios.get(`${baseUrl}remov_project/${id}`);

export const getSaveContacts = async (formDatatest) =>
  await axios.post(`${baseUrl}create_contacts`, formDatatest);

export const getShowContact = async (id) => await axios.get(`${baseUrl}contact_list/${id}`);

export const removeContactList = async (id) => await axios.get(`${baseUrl}remov_contact/${id}`);

export const getSaveDocument = async (formData1) =>
  await axios.post(`${baseUrl}create_document`, formData1);

export const removeDoucmentList = async (id) => await axios.get(`${baseUrl}remov_document/${id}`);

export const getSaveShipping = async (formData) =>
  await axios.post(`${baseUrl}create_shipping`, formData);

export const removeShippingList = async (id) => await axios.get(`${baseUrl}remov_shipping/${id}`);

export const getShowShipping = async (id) => await axios.get(`${baseUrl}shipping_list/${id}`);

export const getUploadCompanyPhoto = async (formData) =>
  await axios.post(`${baseUrl}upload_company_photo`, formData);

export const getUploadFileDd = async (formData) =>
  await axios.post(`${baseUrl}upload_file_documentdelivery`, formData);

export const getCompanyPhotos = async (id) => await axios.get(`${baseUrl}companyphots_list/${id}`);

export const getCompanyMap = async (id) => await axios.get(`${baseUrl}companymap_list/${id}`);

export const getDeleteImgcompany = async (id) =>
  await axios.get(`${baseUrl}delete_imgcompany/${id}`);

export const getDeleteImgDocument = async (id) =>
  await axios.get(`${baseUrl}delete_imgdocument/${id}`);

export const getDeleteImgmap = async (id) => await axios.get(`${baseUrl}delete_companymap/${id}`);

export const getDeleteCertficate = async (id) =>
  await axios.get(`${baseUrl}delete_file_certificate/${id}`);

export const getDeleteReg = async (id) =>
  await axios.get(`${baseUrl}delete_file_registration/${id}`);

export const getDeleteSea = async (id) => await axios.get(`${baseUrl}delete_file_seal/${id}`);

export const getUploadCompanyMap = async (formData) =>
  await axios.post(`${baseUrl}upload_company_map`, formData);

export const getUploadCertificate = async (formData) =>
  await axios.post(`${baseUrl}upload_file_certificate`, formData);

export const getUploadRegistration = async (formData) =>
  await axios.post(`${baseUrl}upload_file_registration`, formData);

export const getUploadSeal = async (formData) =>
  await axios.post(`${baseUrl}upload_file_seal`, formData);

export const getSaveFilePayment = async (formData) =>
  await axios.post(`${baseUrl}upload_file_payment`, formData);

export const getUploadFileShp = async (formData) =>
  await axios.post(`${baseUrl}upload_file_shipping`, formData);

export const removeFilePayment = async (id) =>
  await axios.get(`${baseUrl}delete_file_payment/${id}`);

export const getShowFilePayment = async (id) => await axios.get(`${baseUrl}filepayment_list/${id}`);

export const removeFileShp = async (id) => await axios.get(`${baseUrl}delete_file_shipping/${id}`);

export const getGroups = async () => await axios.get(`${baseUrl}groups`);

export const getSaverDocumentDelivery = async (formData) =>
  await axios.post(`${baseUrl}create_document_delivery`, formData);

export const getDepartment = async () => await axios.get(`${baseUrl}department`);

export const getUserLogin = async (formData) => await axios.post(`${baseUrl}userlogin`,formData);
