/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable import/named */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Drawer,
  useMediaQuery,
  List,
  Typography,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  IconButton,
  Badge,
  Menu,
  Chip,
  Button,Avatar
} from '@mui/material';
//import { Box, MenuItem, Typography, Avatar, Button, Divider } from '@mui/material';

import FeatherIcon from 'feather-icons-react';
import { SidebarWidth } from '../../../assets/global/Theme-variable';
// import LogoIcon from '../logo/LogoIcon';
import Menuitems from './Menuitems';
import Scrollbar from '../../../components/custom-scroll/Scrollbar';
import logo from '../../../assets/images/logo_T.png';
import NotificationDropdown from '../header/NotificationDropdown';
import useAuth from '../../../hooks/useAuth';
//import ProfileDropdown from '../header/ProfileDropdown';
import userimg from '../../../assets/images/users/user10.png';
import { getUserLogin,} from '../../../function/FunctionApi';

const Sidebar = ({ isMobileSidebarOpen, onSidebarClose, isSidebarOpen, data_quotation_notifi, data_order_notifi, handleRefresh }) => {
  const [open, setOpen] = React.useState(true);
  const { pathname } = useLocation();
  const { user, logout } = useAuth();

  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const pathHead = pathname.split("/")[1];
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  // const permission = "Management"; // Management Sales Client


  useEffect(() => {
    const da = JSON.stringify(user.user_level);
    const sa = JSON.parse(da);
    setUserNameTH(sa.name_th);
    setUserEmail(user.email);
   
    const formData = {
      userEmail,
    };
    getUserLogin(formData)
      .then((res) => {
        console.log("PseudoCode", res.data);

        if (res.data.status == 'Error') {
          setUserLogin('');
        } else {
         
          setUserLogin(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });

  }, []);





  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    handleRefresh()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleMore = () => {
    // dispatch(getOrderbyId(data));
    navigate('/notification');
    handleClose()

  };
  const handleMorenoti = (data, op) => {
    if (op === "newcustomer") {
      // navigate('/notification');
    } else if (op === "neworder") {
      navigate(`/saleorderlist/detail/${data.order_id}`);
    } else {
      navigate(`/quotation/update/${data.id}`);
    }
    handleClose()
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [userNameTh, setUserNameTH] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userlogin, setUserLogin] = useState('');
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
    const da = JSON.stringify(user.user_level);
    const sa = JSON.parse(da);
    setUserNameTH(sa.name_th);
    setUserEmail(user.email);
   
    const formData = {
      userEmail,
    };
    getUserLogin(formData)
      .then((res) => {
        console.log("PseudoCode", res.data);

        if (res.data.status == 'Error') {
          setUserLogin('');
        } else {
         
          setUserLogin(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
    
  };


  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      // toast.error('Unable to logout.');
    }
  };

  const newcustomer = [
    // {
    //   title: 'Sumo sushi ส่งคำขอเปิดบัญชีคู่ค้า',
    //   subtitle: '10 ชั่งโมงที่ผ่านมา',
    // },
    // {
    //   title: 'Sumo sushi2 ส่งคำขอเปิดบัญชีคู่ค้า',
    //   subtitle: '10 ชั่งโมงที่ผ่านมา',
    // },
    // {
    //   title: 'Sumo sushi3 ส่งคำขอเปิดบัญชีคู่ค้า',
    //   subtitle: '10 ชั่งโมงที่ผ่านมา',
    // },
  ];

  const SidebarContent = (
    <Scrollbar style={{ height: "100%" }}>
      <Box sx={{ height: "100%" }}>
        {/* <LogoIcon /> */}
        <Grid container sx={{ height: "100%" }}>
          <Grid xs={3} sm={3} lg={3} sx={{ height: "100%", padding: "5%", background: "linear-gradient(0deg, rgba(14,33,48,1) 0%, rgba(28,85,131,1) 35%, rgba(16,100,167,1) 47%, rgba(101,196,182,1) 100%)" }}>

            <Box sx={{ padding: "20%" }}>
              <img src={logo} alt="logo" width="100%" />
            </Box>
            <Box sx={{ "padding-top": "10vh" }}>
              <Typography
                variant="h1"
                sx={{
                  '&': {
                    cursor: 'pointer',
                    textAlign: "center",
                    color: "white",
                    "border-radius": "50%",
                    height: 40,
                    width: 40,
                    ...(pathHead !== 'notification' && { backgroundColor: "#0e21304d" })
                  },
                  '& svg': {
                    "vertical-align": "baseline",
                  }
                }}>
                <FeatherIcon
                  icon="shopping-bag" width="100%" />
              </Typography>
            </Box>
            <Box sx={{ "position": "absolute", "bottom": "25px" }}>
              {user.status !== "Client" && (
                <Box>
                  <IconButton
                    size="large"
                    aria-label="menu"
                    color="inherit"
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    // onClick={handleClick2}
                    sx={{
                      color: "white", "border-radius": "50%", height: 40, width: 40,
                      ...(pathHead === 'notification' && { backgroundColor: "#66bcff4d" })
                    }}
                  >
                    {(data_order_notifi.total > 0 || newcustomer.length > 0 || data_quotation_notifi.total > 0) ?
                      (
                        <Badge variant="dot" color="error">
                          <FeatherIcon icon="bell" width="20" height="20" />
                        </Badge>
                      ) :
                      (
                        <FeatherIcon icon="bell" width="20" height="20" />
                      )
                    }
                  </IconButton>
                  <Menu
                    id="notification-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    sx={{
                      '& .MuiMenu-paper': {
                        width: '470px',
                        left: "55px !important",
                      },
                    }}
                  >
                    <Box
                    >
                      <Box>
                        <Grid container xs={12} sx={{ px: "20px" }}>
                          <Grid item xs={11}>
                            <Typography variant="h2" fontWeight="500">
                              การแจ้งเตือน
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              size="large"
                              aria-label="menu"
                              color="inherit"
                              aria-controls="notification-menu"
                              aria-haspopup="true"
                              sx={{ color: "black" }}
                              onClick={handleMore}
                            >
                              <FeatherIcon icon="more-vertical" width="20" height="20" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid container xs={12} sx={{ px: "20px" }}>
                          <Grid item xs={10}>
                            <Typography variant="h5" fontWeight="500">
                              คำสั่งซื้อใหม่
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              sx={{
                                ml: 2,
                              }}
                            >
                              <Chip
                                size="small"
                                label={data_order_notifi.total}
                                sx={{
                                  borderRadius: '50px',
                                  pl: '10px',
                                  pr: '10px',
                                  backgroundColor: (theme) => theme.palette.primary.main,
                                  color: '#fff',
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container xs={12}>
                          <Grid item xs={12}>
                            <NotificationDropdown data={data_order_notifi.data} handleMorenoti={handleMorenoti} type={{ type: "neworder" }} />
                          </Grid>
                        </Grid>
                        {user.status === "Management" && (
                          <>
                            <Grid container xs={12} sx={{ px: "20px" }}>
                              <Grid item xs={10}>
                                <Typography variant="h5" fontWeight="500">
                                  คำขอเปิดบัญชีใหม่
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Box
                                  sx={{
                                    ml: 2,
                                  }}
                                >
                                  <Chip
                                    size="small"
                                    label={newcustomer.length}
                                    sx={{
                                      borderRadius: '50px',
                                      pl: '10px',
                                      pr: '10px',
                                      backgroundColor: (theme) => theme.palette.primary.main,
                                      color: '#fff',
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container xs={12}>
                              <Grid item xs={12}>
                                <NotificationDropdown data={newcustomer} handleMorenoti={handleMorenoti} type={{ type: "newcustomer" }} />
                              </Grid>
                            </Grid>
                            <Grid container xs={12} sx={{ px: "20px" }}>
                              <Grid item xs={10}>
                                <Typography variant="h5" fontWeight="500">
                                  ใบเสนอราคาใหม่
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Box
                                  sx={{
                                    ml: 2,
                                  }}
                                >
                                  <Chip
                                    size="small"
                                    label={data_quotation_notifi.total}
                                    sx={{
                                      borderRadius: '50px',
                                      pl: '10px',
                                      pr: '10px',
                                      backgroundColor: (theme) => theme.palette.primary.main,
                                      color: '#fff',
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container xs={12}>
                              <Grid item xs={12}>
                                <NotificationDropdown data={data_quotation_notifi.data} handleMorenoti={handleMorenoti} type={{ type: "newquotation" }} />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Menu>
                </Box>
              )}

              <Box sx={{ mt: 1 }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  color="inherit"
                  sx={{ color: "white" }}
                  onClick={handleClick4}
                >
                  <FeatherIcon icon="user" width="20" height="20" />
                </IconButton>
              </Box>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl4}
                keepMounted
                open={Boolean(anchorEl4)}
                onClose={handleClose4}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                  '& .MuiMenu-paper': {
                    width: '385px',
                    right: 0,
                    // top: '70px !important',
                  },
                  '& .MuiList-padding': {
                    p: '30px',
                  },
                }}
              >
                 <Box
                  sx={{
                    mb: 1,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="h4" fontWeight="500">
                      User Profile
                    </Typography>
                  </Box>
                </Box> 

                {/* <ProfileDropdown /> */}
               <>
               <Box>
                <Box
                  sx={{
                    pb: 3,
                    mt: 3,
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      src={userimg}
                      alt={userimg}
                      sx={{
                        width: '90px',
                        height: '90px',
                      }}
                    />
                    <Box
                      sx={{
                        ml: 2,
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          lineHeight: '1.235',
                        }}
                      >
                        {userlogin}
                      </Typography>
                      <Typography color="textSecondary" variant="h6" fontWeight="400">
                        {userNameTh}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Typography
                          color="textSecondary"
                          display="flex"
                          alignItems="center"
                          sx={{
                            color: (theme) => theme.palette.grey.A200,
                            mr: 1,
                          }}
                        >
                          <FeatherIcon icon="mail" width="18" />
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                          {user.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                </Box>
               </>
                <Button
                  sx={{
                    mt: 2,
                    display: 'block',
                    width: '100%',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Menu>
            </Box>
          </Grid>
          <Grid xs={9} sm={9} lg={9}>
            <Box>
              <List sx={{ "padding-top": "15vh" }}>
                {Menuitems.map((item, index) => {
                  if (item.permission.includes(user.status)) {
                    if (item.subheader) {
                      return (
                        <li key={item.subheader}>
                          <Typography
                            variant="subtitle2"
                            fontWeight="500"
                            sx={{ my: 2, mt: 4, opacity: '0.4' }}
                          >
                            {item.subheader}
                          </Typography>
                        </li>
                      );
                      // {/********If Sub Menu**********/}
                      /* eslint no-else-return: "off" */
                    } else if (item.children) {
                      return (
                        <React.Fragment key={item.title}>
                          <ListItem
                            button
                            component="li"
                            onClick={() => handleClick(index)}
                            selected={pathWithoutLastPart === item.href}
                            sx={{
                              mb: 1,
                              ...(pathWithoutLastPart === item.href && {
                                color: 'white',
                                backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
                              }),
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                ...(pathWithoutLastPart === item.href && {
                                  color: 'white',
                                }),
                              }}
                            >
                              <FeatherIcon icon={item.icon} width="20" height="20" />
                            </ListItemIcon>
                            <ListItemText>{item.title}</ListItemText>
                            {index === open || pathWithoutLastPart === item.href ? (
                              <FeatherIcon icon="chevron-down" size="16" />
                            ) : (
                              <FeatherIcon icon="chevron-right" size="16" />
                            )}
                          </ListItem>
                          <Collapse in={index === open} timeout="auto" unmountOnExit>
                            <List component="li" disablePadding>
                              {item.children.map((child) => {
                                return (
                                  <ListItem
                                    key={child.title}
                                    button
                                    component={NavLink}
                                    to={child.href}
                                    onClick={onSidebarClose}
                                    selected={pathDirect === child.href}
                                    sx={{
                                      mb: 1,
                                      ...(pathDirect === child.href && {
                                        color: 'primary.main',
                                        backgroundColor: 'transparent!important',
                                      }),
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        svg: { width: '14px', marginLeft: '3px' },
                                        ...(pathDirect === child.href && {
                                          color: 'primary.main',
                                        }),
                                      }}
                                    >
                                      <FeatherIcon icon={child.icon} width="20" height="20" />
                                    </ListItemIcon>
                                    <ListItemText>{child.title}</ListItemText>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      );
                      // {/********If Sub No Menu**********/}
                    } else {
                      return (
                        <List component="li" disablePadding key={item.title}>
                          <ListItem
                            onClick={() => handleClick(index)}
                            button
                            component={NavLink}
                            to={item.href}
                            selected={pathDirect === item.href || pathDirect === item.key}
                            sx={{
                              mb: 1,
                              ...((pathDirect === item.href || pathDirect === item.key || pathHead === item.head) && {
                                color: 'white !important',
                                backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
                                "border-radius": 0,
                              }),
                            }}
                          >
                            <ListItemText onClick={onSidebarClose}>{item.title}</ListItemText>
                          </ListItem>
                        </List>
                      );
                    }

                  }
                  // {/********SubHeader**********/}

                })}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Scrollbar>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
            border: '0 !important',
            boxShadow: '0px 7px 30px 0px rgb(113 122 131 / 11%)',
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
          border: '0 !important',
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
};

Sidebar.propTypes = {
  isMobileSidebarOpen: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default Sidebar;