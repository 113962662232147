/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { experimentalStyled, useMediaQuery, Container, Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from './sidebar/Sidebar';
import Header from './header/Header';
import Footer from './footer/Footer';
// import Customizer from './customizer/Customizer';
import { TopbarHeight } from '../../assets/global/Theme-variable';
import Scrollbar from '../../components/custom-scroll/Scrollbar';
import { getorder_notifi, getquotation_notifi } from '../../redux/notifi/Action';
import useAuth from '../../hooks/useAuth';

const MainWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
}));
const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',

  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    paddingTop: TopbarHeight,
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: '64px',
  },
}));

const FullLayout = () => {
  const { user } = useAuth();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const customizer = useSelector((state) => state.CustomizerReducer);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { data_order_notifi, data_quotation_notifi } = useSelector((state) => state.NotifiReducer);

  // const handleRefresh = () => {
  //   dispatch(getorder_notifi({ status_active: 0 }));
  //   if(user.status === "Management")dispatch(getquotation_notifi({ status_active: 1 }));
  // };
  // React.useEffect(() => {
  //   handleRefresh()
  // }, []);
  return (
    <MainWrapper className={customizer.activeMode === 'dark' ? 'darkbg' : ''}>
      <Header
        sx={{
          paddingLeft: isSidebarOpen && lgUp ? '265px' : '',
          backgroundColor: customizer.activeMode === 'dark' ? '#20232a' : '#fafbfb',
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        toggleMobileSidebar={() => setMobileSidebarOpen(true)}
      />

      <Sidebar
        isSidebardir={customizer.activeDir === 'ltr' ? 'left' : 'right'}
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
        onSidebarClose={() => setMobileSidebarOpen(false)}
        data_order_notifi={data_order_notifi}
        handleRefresh={() => handleRefresh()}
        data_quotation_notifi={data_quotation_notifi}
      />

      <PageWrapper>
        <Container
          maxWidth={false}
          sx={{
            paddingLeft: isSidebarOpen && lgUp ? '280px!important' : '',
          }}
        >
          <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
            <Scrollbar style={{ height: "100%" }}>
              <Outlet />
            </Scrollbar>
          </Box>
          {/* <Customizer /> */}
          <Footer />
        </Container>
      </PageWrapper>
    </MainWrapper>
  );
};

export default FullLayout;
