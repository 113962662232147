/* eslint-disable no-unused-vars */
import {
  GET_ORDER,
  GET_PARTNER_GROUP,
  GET_ORDER_DETAIL,
  GET_Tombons,
  GET_AMPHURES,
  GET_PROVINCE,
  FILTER_ORDER,
  GET_ORDER_DETAIL_ID
} from '../constants';

const INIT_STATE = {
  data: { data: [], count: 0 },
  partner_group_data: { data: [], count: 0 },
  tombon_data: { data: [], count: 0 },
  amphure_data: { data: [], count: 0 },
  province_data: { data: [], count: 0 },
  filters: {
    page: 1,
    limit: 10,
    sort: 'desc',
    order_by: 'created_datetime'
  },
  initial: {
    approve_by: null,
    approve_datetime: null,
    contact_department: "",
    contact_firstname: "",
    contact_lastname: "",
    contact_nickname: "",
    contact_person_id: null,
    contact_person_number: null,
    contact_person_phone: "",
    contact_position: "",
    created_datetime: null,
    desired_date: null,
    full_pnrg_id: "",
    group_name: "",
    id: null,
    juristic_id: "",
    juristic_name: "",
    juristic_name_en: "",
    juristic_name_th: "",
    juristic_other: null,
    juristic_type_id: null,
    order_id: "",
    partners_id: "",
    person_type_id: null,
    person_type_name: "",
    quotation_id: null,
    shipping_address_id: null,
    shipping_fee: null,
    status_active: null,
    total_price: null,
    updated_by: null,
    updated_datetime: null,
    vw_order_detail: [],
    vw_shipping_address: []
  },
  initial_id: {
    vw_order_detail: [],
    vw_shipping_address: []
  }

};

const OrderReducer = (state = INIT_STATE, action) => {
  console.log(action)
  switch (action.type) {
    case GET_ORDER_DETAIL:

      return {
        ...state,
        initial: action.data.id ? action.data : {
          approve_by: null,
          approve_datetime: null,
          contact_department: "",
          contact_firstname: "",
          contact_lastname: "",
          contact_nickname: "",
          contact_person_id: null,
          contact_person_number: null,
          contact_person_phone: "",
          contact_position: "",
          created_datetime: null,
          desired_date: null,
          full_pnrg_id: "",
          group_name: "",
          id: null,
          juristic_id: "",
          juristic_name: "",
          juristic_name_en: "",
          juristic_name_th: "",
          juristic_other: null,
          juristic_type_id: null,
          order_id: "",
          partners_id: "",
          person_type_id: null,
          person_type_name: "",
          quotation_id: null,
          shipping_address_id: null,
          shipping_fee: null,
          status_active: null,
          total_price: null,
          updated_by: null,
          updated_datetime: null,
          vw_order_detail: [],
          vw_shipping_address: []
        },
      };

    case GET_ORDER:
      return {
        ...state,
        data: action.data.data,
      };

    case GET_PARTNER_GROUP:
      return {
        ...state,
        partner_group_data: action.data.data,
      };

    case GET_Tombons:
      return {
        ...state,
        tombon_data: action.data.data,
      };

    case GET_AMPHURES:
      return {
        ...state,
        amphure_data: action.data.data,
      };

    case GET_PROVINCE:
      return {
        ...state,
        province_data: action.data.data,
      };

    // case GET_PRODUCT_UNIT:
    //   return {
    //     ...state,
    //     unit: action.data.data,
    //   };
    // case GET_PRODUCT_TYPE:
    //   return {
    //     ...state,
    //     type: action.data.data,
    //   };
    case FILTER_ORDER:
      return {
        ...state,
        filters: action.data,
      };

    case GET_ORDER_DETAIL_ID:
      console.log(action.data.id)

      return {
        ...state,
        initial_id: action.data.id ? action.data : {
          vw_order_detail: [],
          vw_shipping_address: []
        },
      };




    default:
      return state;
  }
};

export default OrderReducer;
