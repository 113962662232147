import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { ReactComponent as LogoDark } from '../../../assets/images/logos/logo-dark.svg';
// import { ReactComponent as LogoLight } from '../../../assets/images/logos/logo-white.svg';
import LogoDark from '../../../assets/images/logo_all.png';
// import LogoDark from '../assets/images/logo_all.png';


const LogoIconToLogin = () => {
  const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <Link underline="none" to="/auth/login" style={{'align-items':'center'}}>
      {customizer.activeMode === 'dark' ? <img  src={LogoDark} style={{width:"500px",position: 'relative',
  left: 'calc(50% -250)','align-items':'center'}}/> :
  <img  src={LogoDark} style={{width:"500px",position: 'relative',
  left: 'calc(50% - 250px)','align-items':'center'}}/>}
    </Link>
  );
};

export default LogoIconToLogin;
