/* eslint-disable no-unused-vars */
import {
  GET_QUOTATION_NOTIFI,
  GET_ORDER_NOTIFI,
} from '../constants';

const INIT_STATE = {
  data_quotation_notifi: { data: [], count: 0 },
  data_order_notifi: { data: [], vw_product: [], count: 0 },




};

const NotifiReducer = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case GET_QUOTATION_NOTIFI:
      return {
        ...state,
        data_quotation_notifi: action.data.data,
      };
    case GET_ORDER_NOTIFI:
      return {
        ...state,
        data_order_notifi: action.data.data,
      };

    default:
      return state;
  }
};

export default NotifiReducer;
