/* eslint-disable react/react-in-jsx-scope */
import { Box } from '@mui/material';


// eslint-disable-next-line import/extensions
import LogoIconToLogin from '../layouts/full-layout/logo/LogoIconToLogin';


const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    <LogoIconToLogin />
  </Box>
);

export default SlashScreen;
