import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => (
  <Box sx={{ p: 3, textAlign: 'center' }}>
    <Box>
      <Typography>© 2022 BUMRUNGTHAI.</Typography>
    </Box>
    <Box>
      <Typography sx={{ '& a': {textDecoration: 'none'} }}>
        DIGITALIZED BY <a target="_blank" rel="noreferrer" href="https://tlogical.com/">
          T.LOGICAL.
        </a> All right reserved</Typography>
    </Box>
    
  </Box>
);

export default Footer;
