const Menuitems = [
  // {
  //   navlabel: true,
  //   subheader: ' ',
  //   icon: 'mdi mdi-dots-horizontal',
  //   href: 'Dashboard',
  // },
  // {
  //   title: 'ฝ่ายบริหาร',
  //   icon: 'shopping-bag',
  //   href: '/shop',
  //   collapse: true,
  //   children: [
  //     {
  //       title: 'ภาพรวม',
  //       icon: 'pie-chart',
  //       href: '/dashboards/dashboard1',
  //     },
  //     {
  //       title: 'การจัดการสินค้า',
  //       icon: 'list',
  //       href: '/product/lists',
  //     },
  //   ],
  // },
  // {
  //   title: 'ภาพรวม',
  //   //  href: '/dashboards/dashboard1',
  //   href: '/',
  //   key: '/',
  //   head: 'dashboards',
  //   permission: ['Management', 'Sales']

  // },
  {
    title: 'รายงานประจำวัน',
    href: '/dailyreport/calendar',
    key: '/dailyreport/create',
    head: 'dailyreport',
    permission: ['Management', 'Sales',]
  },
  
  // {
  //   title: 'ใบเสนอราคา',
  //   href: '/quotation',
  //   permission: ['Management', 'Sales',]
  // },
  // {
  //   title: 'ประเมินราคา',
  //   href: '/appraise',
  //   permission: ['Management', 'Sales',]
  // },

  // {
  //   title: 'ใบจอง',
  //   href: '/preemption_certificate',
  //   permission: ['Management', 'Sales',]
  // },
  // {
  //   title: 'ใบส่งสินค้า',
  //   href: '/delivery_note',
  //   permission: ['Management', 'Sales',]
  // },
  // {
  //   title: 'ตรวจสอบสถานะสินค้า',
  //   href: '/check_product_status',
  //   permission: ['Management', 'Sales',]
  // },
  // {
  //   title: 'ใบแจ้งคืนสินค้า',
  //   href: '/return_notice',
  //   permission: ['Management', 'Sales',]
  // },
  // {
  //   title: 'แจ้งการเปลี่ยนแปลง',
  //   href: '/notify_the_change',
  //   permission: ['Management', 'Sales',]
  // },
  // {
  //   title: 'เปิดบัญชีคู่ค้า',
  //   href: '/partner_account',
  //   permission: ['Management', 'Sales',]
  // },
  {
    title: 'บัญชีลูกค้า',
    href: '/customers/lists',
    permission: ['Management', 'Sales',]
  }
  // ,
  //   {
  //   title: 'Management',
  //   href: '/management/lists',
  //   permission: ['Management', 'Sales',]
  // }
];

export default Menuitems;
