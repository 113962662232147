/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import {
  FILTER_QUOTATION,
  GET_QUOTATION,
  GET_QUOTATIONBYID,
  GET_QUOTATION_DETAIL,
  ADD_QUOTATION,
  ADD_QUOTATIONBYID,
  GET_PRODUCT_BY_PRODUCT_ID
} from '../constants';

const INIT_STATE = {
  data_product: undefined,
  data_quotation: { data: [], count: 0 },
  data_quotationbyId: {},
  data_quotation_detail: [],
  data_createquotation: {},
  data_createquotationbyid: {},
  filters: {
    page: 0,
    limit: 10,
    sort: 'desc',
    order_by: 'quotation_id'
  },

};

const QuotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FILTER_QUOTATION:
      return {
        ...state,
        filters: action.data,
      };
    case GET_QUOTATION:
      return {
        ...state,
        data_quotation: action.data.data,
      };
    case GET_PRODUCT_BY_PRODUCT_ID:
      return {
        ...state,
        data_product: action.data.data.data[0],
      };
    case GET_QUOTATIONBYID:
      return {
        ...state,
        data_quotationbyId: action.data.data.data,
        data_product: undefined,
        data_quotation: { data: [], count: 0 },
        data_quotation_detail: [],
      };
    case GET_QUOTATION_DETAIL:
      return {
        ...state,
        data_quotation_detail: action?.data?.data?.data || [],
      };
    case ADD_QUOTATION:
      return {
        ...state,
        data_createquotation: action.data.data,
      };
    case ADD_QUOTATIONBYID:
      return {
        ...state,
        data_createquotationbyid: action.data.data,
      };

    default:
      return state;
  }
};

export default QuotationReducer;
