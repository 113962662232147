/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

// import BookingList from '../pages/management/booking/BookingList';
// import FarmList from '../pages/management/farm/FarmList';


const index_role = {
  'Management': "/dailyreport/calendar",
  'Sales': "/dailyreport/calendar",
  'Client': "/dailyreport/calendar",
}


const Indexofrole = (props) => {
  const { user } = useAuth();
  console.log("NavigateNavigate")
  // eslint-disable-next-line vars-on-top
  var indexpage = index_role[user.status]
  // return <>{indexpage}</>;
  return <Navigate to={indexpage} />
};

Indexofrole.propTypes = {
  children: PropTypes.node
};

export default Indexofrole;
