/* eslint-disable camelcase */
// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';
// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// PRODUCT CONSTANTS
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const FILTER_PRODUCT = 'FILTER_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_BY_PRODUCT_ID = 'GET_PRODUCT_BY_PRODUCT_ID';
export const GET_PRODUCT_TYPE = 'GET_PRODUCT_TYPE';
export const GET_PRODUCT_UNIT = 'GET_PRODUCT_UNIT';

// SHOP CONSTANTS
export const GET_ORDER_CART = 'GET_ORDER_CART';
export const ADD_ORDER_CART = 'ADD_ORDER_CART';
export const DELETE_ORDER_CART = 'DELETE_ORDER_CART';
export const GET_PRODUCT_FAVOURITE = 'GET_PRODUCT_FAVOURITE';
export const GET_PRODUCT_FAVOURITE_NUM = 'GET_PRODUCT_FAVOURITE_NUM';
export const DELETE_PRODUCT_FAVOURITE = 'DELETE_PRODUCT_FAVOURITE';
export const ADD_PRODUCT_FAVOURITE = 'ADD_PRODUCT_FAVOURITE';
export const GET_PRODUCT_RECOMMENDED = 'GET_PRODUCT_RECOMMENDED';

// ORDER CONSTANTS
export const GET_ORDER = 'GET_ORDER';
export const GET_PARTNER_GROUP = 'GET_PARTNER_GROUP';
export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL';
export const FILTER_ORDER = 'FILTER_ORDER';
export const GET_ORDER_DETAIL_ID = 'GET_ORDER_DETAIL_ID';





// ADDRESS CONSTANTS
export const GET_PROVINCE = 'GET_PROVINCE';
export const GET_AMPHURES = 'GET_AMPHURES';
export const GET_Tombons = 'GET_Tombons';
export const GET_Zipcode = 'GET_Zipcode';
export const GET_shipping_address = 'GET_shipping_address';
export const GET_contact_person = 'GET_contact_person';
export const GET_restaurant = 'GET_restaurant';
export const GET_customer = 'GET_customer';
export const GET_customers = 'GET_customers';
export const GET_group_partner = 'GET_group_partner';
export const GET_employee = 'GET_employee';

// QUOTATION CONSTANTS
export const FILTER_QUOTATION = 'FILTER_QUOTATION';
export const GET_QUOTATION = 'GET_QUOTATION';
export const GET_QUOTATIONBYID = 'GET_QUOTATIONBYID';
export const GET_QUOTATION_DETAIL = 'GET_QUOTATION_DETAIL';
export const ADD_QUOTATION = 'ADD_QUOTATION';
export const ADD_QUOTATIONBYID = 'ADD_QUOTATIONBYID';
export const QUOTATION_CLEAR = 'QUOTATION_CLEAR';


// QUOTATION CONSTANTS
export const GET_ORDER_NOTIFI = 'GET_ORDER_NOTIFI';
export const GET_QUOTATION_NOTIFI = 'GET_QUOTATION_NOTIFI';

// DAILY_REPORT CONSTANTS
export const FILTER_DAILY_REPORT = 'FILTER_DAILY_REPORT';
export const SET_DAILY_REPORT = 'SET_DAILY_REPORT';
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_CONTACT_PERSON = 'GET_CONTACT_PERSON';
export const GET_PROVINCES = 'GET_PROVINCES';
export const GET_LEAVE_TYPE = 'GET_LEAVE_TYPE';
export const GET_DAILY_REPORT = 'GET_DAILY_REPORT';
export const GET_DAILY_REPORT_USER = 'GET_DAILY_REPORT_USER';
export const GET_DAILY_REPORT_BY_ID = 'GET_DAILY_REPORT_BY_ID';
export const GET_WORK_TYPE = 'GET_WORK_TYPE';
export const GET_PARTNER_TYPE = 'GET_PARTNER_TYPE';
export const GET_PARTNER_GROUP_DETAILS = 'GET_PARTNER_GROUP_DETAILS';
export const GET_PARTNER_PROJECTS = 'GET_PARTNER_PROJECTS';
export const GET_LOADING = 'GET_LOADING';


