/* eslint-disable dot-notation */
import axios from 'axios';

// const axiosInstance = axios.create();
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-type": "application/json"
  }
});
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common['Authorization'];
      delete axios.defaults.headers.common['authtoken'];

      window.location = "/auth/login"
    }
    return error.response
    // Promise.reject((error.response) || 'Something went wrong')
  }
);

export default axiosInstance;
