
/* eslint-disable no-else-return */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Login from '../views/authentication/Login';
// import NotFound from '../pages/NotFound';

const PermissionGuard = (props) => {
  const { children, permission } = props;
  const {isAuthenticated, user} = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = React.useState(null);
  console.log(isAuthenticated)
  if(!isAuthenticated){
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login/>;
    }
  else if (!permission.includes(user.status)) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
      
    return <Navigate to="/404" />;
  }
//   return <><children/></>;
  return <>{children}</>;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.array
};

export default PermissionGuard;
