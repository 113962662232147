import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import productReducer from './product/ProductReducer';
import ShopReducer from './shop/ShopReducer';
import AddressReducer from './address/AddressReducer';
import OrderReducer from './order/OrderReducer';
import QuotationReducer from './quotation/QuotationReducer';
import NotifiReducer from './notifi/NotifiReducer';
import DailyreportReducer from './dailyreport/DailyreportReducer';



const RootReducers = combineReducers({
  CustomizerReducer,
  productReducer,
  ShopReducer,
  AddressReducer,
  OrderReducer,
  QuotationReducer,
  NotifiReducer,
  DailyreportReducer
});

export default RootReducers;
